/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-select-auto-complete {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #434343;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
}
.ant-select-auto-complete .ant-select-clear {
  right: 13px;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-picker-calendar {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #434343;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  background: #fff;
}
.ant-picker-calendar-header {
  display: flex;
  justify-content: flex-end;
  padding: 12px 0;
}
.ant-picker-calendar-header .ant-picker-calendar-year-select {
  min-width: 80px;
}
.ant-picker-calendar-header .ant-picker-calendar-month-select {
  min-width: 70px;
  margin-left: 8px;
}
.ant-picker-calendar-header .ant-picker-calendar-mode-switch {
  margin-left: 8px;
}
.ant-picker-calendar .ant-picker-panel {
  background: #fff;
  border: 0;
  border-top: 1px solid #f0f0f0;
  border-radius: 0;
}
.ant-picker-calendar .ant-picker-panel .ant-picker-month-panel,
.ant-picker-calendar .ant-picker-panel .ant-picker-date-panel {
  width: auto;
}
.ant-picker-calendar .ant-picker-panel .ant-picker-body {
  padding: 8px 0;
}
.ant-picker-calendar .ant-picker-panel .ant-picker-content {
  width: 100%;
}
.ant-picker-calendar-mini {
  border-radius: 4px;
}
.ant-picker-calendar-mini .ant-picker-calendar-header {
  padding-right: 8px;
  padding-left: 8px;
}
.ant-picker-calendar-mini .ant-picker-panel {
  border-radius: 0 0 4px 4px;
}
.ant-picker-calendar-mini .ant-picker-content {
  height: 256px;
}
.ant-picker-calendar-mini .ant-picker-content th {
  height: auto;
  padding: 0;
  line-height: 18px;
}
.ant-picker-calendar-mini .ant-picker-cell::before {
  pointer-events: none;
}
.ant-picker-calendar-full .ant-picker-panel {
  display: block;
  width: 100%;
  text-align: right;
  background: #fff;
  border: 0;
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-body th,
.ant-picker-calendar-full .ant-picker-panel .ant-picker-body td {
  padding: 0;
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-body th {
  height: auto;
  padding: 0 12px 5px 0;
  line-height: 18px;
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell::before {
  display: none;
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell:hover .ant-picker-calendar-date {
  background: #f5f5f5;
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell .ant-picker-calendar-date-today::before {
  display: none;
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date,
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date,
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date-today,
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date-today {
  background: #fff6e6;
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date .ant-picker-calendar-date-value,
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date .ant-picker-calendar-date-value,
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date-today .ant-picker-calendar-date-value,
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date-today .ant-picker-calendar-date-value {
  color: #f47607;
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date {
  display: block;
  width: auto;
  height: auto;
  margin: 0 4px;
  padding: 4px 8px 0;
  border: 0;
  border-top: 2px solid #f0f0f0;
  border-radius: 0;
  transition: background 0.3s;
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-value {
  line-height: 24px;
  transition: color 0.3s;
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-content {
  position: static;
  width: auto;
  height: 86px;
  overflow-y: auto;
  color: #434343;
  line-height: 1.5715;
  text-align: left;
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-today {
  border-color: #f47607;
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-today .ant-picker-calendar-date-value {
  color: #434343;
}
@media only screen and (max-width: 480px) {
  .ant-picker-calendar-header {
    display: block;
  }
  .ant-picker-calendar-header .ant-picker-calendar-year-select {
    width: 50%;
  }
  .ant-picker-calendar-header .ant-picker-calendar-month-select {
    width: calc(50% - 8px);
  }
  .ant-picker-calendar-header .ant-picker-calendar-mode-switch {
    width: 100%;
    margin-top: 8px;
    margin-left: 0;
  }
  .ant-picker-calendar-header .ant-picker-calendar-mode-switch > label {
    width: 50%;
    text-align: center;
  }
}
.ant-picker-calendar-rtl {
  direction: rtl;
}
.ant-picker-calendar-rtl .ant-picker-calendar-header .ant-picker-calendar-month-select {
  margin-right: 8px;
  margin-left: 0;
}
.ant-picker-calendar-rtl .ant-picker-calendar-header .ant-picker-calendar-mode-switch {
  margin-right: 8px;
  margin-left: 0;
}
.ant-picker-calendar-rtl.ant-picker-calendar-full .ant-picker-panel {
  text-align: left;
}
.ant-picker-calendar-rtl.ant-picker-calendar-full .ant-picker-panel .ant-picker-body th {
  padding: 0 0 5px 12px;
}
.ant-picker-calendar-rtl.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-content {
  text-align: right;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-radio-group {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #434343;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  display: inline-block;
  font-size: 0;
}
.ant-radio-group .ant-badge-count {
  z-index: 1;
}
.ant-radio-group > .ant-badge:not(:first-child) > .ant-radio-button-wrapper {
  border-left: none;
}
.ant-radio-wrapper {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #434343;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  display: inline-flex;
  align-items: baseline;
  margin-right: 8px;
  cursor: pointer;
}
.ant-radio-wrapper-disabled {
  cursor: not-allowed;
}
.ant-radio-wrapper::after {
  display: inline-block;
  width: 0;
  overflow: hidden;
  content: '\a0';
}
.ant-radio-wrapper.ant-radio-wrapper-in-form-item input[type='radio'] {
  width: 14px;
  height: 14px;
}
.ant-radio {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #434343;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  top: 0.2em;
  display: inline-block;
  outline: none;
  cursor: pointer;
}
.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus + .ant-radio-inner {
  border-color: #f47607;
}
.ant-radio-input:focus + .ant-radio-inner {
  box-shadow: 0 0 0 3px #fff6e6;
}
.ant-radio-checked::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #f47607;
  border-radius: 50%;
  visibility: hidden;
  animation: antRadioEffect 0.36s ease-in-out;
  animation-fill-mode: both;
  content: '';
}
.ant-radio:hover::after,
.ant-radio-wrapper:hover .ant-radio::after {
  visibility: visible;
}
.ant-radio-inner {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 16px;
  height: 16px;
  background-color: #fff;
  border-color: #d9d9d9;
  border-style: solid;
  border-width: 1px;
  border-radius: 50%;
  transition: all 0.3s;
}
.ant-radio-inner::after {
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  width: 16px;
  height: 16px;
  margin-top: -8px;
  margin-left: -8px;
  background-color: #f47607;
  border-top: 0;
  border-left: 0;
  border-radius: 16px;
  transform: scale(0);
  opacity: 0;
  transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  content: ' ';
}
.ant-radio-input {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  cursor: pointer;
  opacity: 0;
}
.ant-radio-checked .ant-radio-inner {
  border-color: #f47607;
}
.ant-radio-checked .ant-radio-inner::after {
  transform: scale(0.5);
  opacity: 1;
  transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-radio-disabled {
  cursor: not-allowed;
}
.ant-radio-disabled .ant-radio-inner {
  background-color: #f5f5f5;
  border-color: #d9d9d9 !important;
  cursor: not-allowed;
}
.ant-radio-disabled .ant-radio-inner::after {
  background-color: rgba(0, 0, 0, 0.2);
}
.ant-radio-disabled .ant-radio-input {
  cursor: not-allowed;
}
.ant-radio-disabled + span {
  color: #d4d4d4;
  cursor: not-allowed;
}
span.ant-radio + * {
  padding-right: 8px;
  padding-left: 8px;
}
.ant-radio-button-wrapper {
  position: relative;
  display: inline-block;
  height: 32px;
  margin: 0;
  padding: 0 15px;
  color: #434343;
  font-size: 14px;
  line-height: 30px;
  background: #fff;
  border: 1px solid #d9d9d9;
  border-top-width: 1.02px;
  border-left-width: 0;
  cursor: pointer;
  transition: color 0.3s, background 0.3s, border-color 0.3s, box-shadow 0.3s;
}
.ant-radio-button-wrapper a {
  color: #434343;
}
.ant-radio-button-wrapper > .ant-radio-button {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
}
.ant-radio-group-large .ant-radio-button-wrapper {
  height: 40px;
  font-size: 16px;
  line-height: 38px;
}
.ant-radio-group-small .ant-radio-button-wrapper {
  height: 24px;
  padding: 0 7px;
  line-height: 22px;
}
.ant-radio-button-wrapper:not(:first-child)::before {
  position: absolute;
  top: -1px;
  left: -1px;
  display: block;
  box-sizing: content-box;
  width: 1px;
  height: 100%;
  padding: 1px 0;
  background-color: #d9d9d9;
  transition: background-color 0.3s;
  content: '';
}
.ant-radio-button-wrapper:first-child {
  border-left: 1px solid #d9d9d9;
  border-radius: 4px 0 0 4px;
}
.ant-radio-button-wrapper:last-child {
  border-radius: 0 4px 4px 0;
}
.ant-radio-button-wrapper:first-child:last-child {
  border-radius: 4px;
}
.ant-radio-button-wrapper:hover {
  position: relative;
  color: #f47607;
}
.ant-radio-button-wrapper:focus-within {
  box-shadow: 0 0 0 3px #fff6e6;
}
.ant-radio-button-wrapper .ant-radio-inner,
.ant-radio-button-wrapper input[type='checkbox'],
.ant-radio-button-wrapper input[type='radio'] {
  width: 0;
  height: 0;
  opacity: 0;
  pointer-events: none;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  z-index: 1;
  color: #f47607;
  background: #fff;
  border-color: #f47607;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
  background-color: #f47607;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child {
  border-color: #f47607;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  color: #ff9830;
  border-color: #ff9830;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover::before {
  background-color: #ff9830;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active {
  color: #cf5a00;
  border-color: #cf5a00;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active::before {
  background-color: #cf5a00;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
  box-shadow: 0 0 0 3px #fff6e6;
}
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: #fff;
  background: #f47607;
  border-color: #f47607;
}
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  color: #fff;
  background: #ff9830;
  border-color: #ff9830;
}
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active {
  color: #fff;
  background: #cf5a00;
  border-color: #cf5a00;
}
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
  box-shadow: 0 0 0 3px #fff6e6;
}
.ant-radio-button-wrapper-disabled {
  color: #d4d4d4;
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  cursor: not-allowed;
}
.ant-radio-button-wrapper-disabled:first-child,
.ant-radio-button-wrapper-disabled:hover {
  color: #d4d4d4;
  background-color: #f5f5f5;
  border-color: #d9d9d9;
}
.ant-radio-button-wrapper-disabled:first-child {
  border-left-color: #d9d9d9;
}
.ant-radio-button-wrapper-disabled.ant-radio-button-wrapper-checked {
  color: #d4d4d4;
  background-color: #e6e6e6;
  border-color: #d9d9d9;
  box-shadow: none;
}
@keyframes antRadioEffect {
  0% {
    transform: scale(1);
    opacity: 0.5;
  }
  100% {
    transform: scale(1.6);
    opacity: 0;
  }
}
.ant-radio-group.ant-radio-group-rtl {
  direction: rtl;
}
.ant-radio-wrapper.ant-radio-wrapper-rtl {
  margin-right: 0;
  margin-left: 8px;
  direction: rtl;
}
.ant-radio-button-wrapper.ant-radio-button-wrapper-rtl {
  border-right-width: 0;
  border-left-width: 1px;
}
.ant-radio-button-wrapper.ant-radio-button-wrapper-rtl.ant-radio-button-wrapper:not(:first-child)::before {
  right: -1px;
  left: 0;
}
.ant-radio-button-wrapper.ant-radio-button-wrapper-rtl.ant-radio-button-wrapper:first-child {
  border-right: 1px solid #d9d9d9;
  border-radius: 0 4px 4px 0;
}
.ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child {
  border-right-color: #ff9830;
}
.ant-radio-button-wrapper.ant-radio-button-wrapper-rtl.ant-radio-button-wrapper:last-child {
  border-radius: 4px 0 0 4px;
}
.ant-radio-button-wrapper.ant-radio-button-wrapper-rtl.ant-radio-button-wrapper-disabled:first-child {
  border-right-color: #d9d9d9;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-popover {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #434343;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1030;
  font-weight: normal;
  white-space: normal;
  text-align: left;
  cursor: auto;
  -webkit-user-select: text;
     -moz-user-select: text;
      -ms-user-select: text;
          user-select: text;
}
.ant-popover-content {
  position: relative;
}
.ant-popover::after {
  position: absolute;
  background: rgba(255, 255, 255, 0.01);
  content: '';
}
.ant-popover-hidden {
  display: none;
}
.ant-popover-placement-top,
.ant-popover-placement-topLeft,
.ant-popover-placement-topRight {
  padding-bottom: 15.3137085px;
}
.ant-popover-placement-right,
.ant-popover-placement-rightTop,
.ant-popover-placement-rightBottom {
  padding-left: 15.3137085px;
}
.ant-popover-placement-bottom,
.ant-popover-placement-bottomLeft,
.ant-popover-placement-bottomRight {
  padding-top: 15.3137085px;
}
.ant-popover-placement-left,
.ant-popover-placement-leftTop,
.ant-popover-placement-leftBottom {
  padding-right: 15.3137085px;
}
.ant-popover-inner {
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.15) \9;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .ant-popover {
    /* IE10+ */
  }
  .ant-popover-inner {
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  }
}
.ant-popover-title {
  min-width: 177px;
  min-height: 32px;
  margin: 0;
  padding: 5px 16px 4px;
  color: #515356;
  font-weight: 500;
  border-bottom: 1px solid #f0f0f0;
}
.ant-popover-inner-content {
  padding: 12px 16px;
  color: #434343;
}
.ant-popover-message {
  position: relative;
  padding: 4px 0 12px;
  color: #434343;
  font-size: 14px;
}
.ant-popover-message > .anticon {
  position: absolute;
  top: 8.0005px;
  color: #faad14;
  font-size: 14px;
}
.ant-popover-message-title {
  padding-left: 22px;
}
.ant-popover-buttons {
  margin-bottom: 4px;
  text-align: right;
}
.ant-popover-buttons button {
  margin-left: 8px;
}
.ant-popover-arrow {
  position: absolute;
  display: block;
  width: 22px;
  height: 22px;
  overflow: hidden;
  background: transparent;
  pointer-events: none;
}
.ant-popover-arrow-content {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 11.3137085px;
  height: 11.3137085px;
  margin: auto;
  background-color: #fff;
  content: '';
  pointer-events: auto;
  border-radius: 0 0 2px;
  pointer-events: none;
}
.ant-popover-arrow-content::before {
  position: absolute;
  top: -11.3137085px;
  left: -11.3137085px;
  width: 33.9411255px;
  height: 33.9411255px;
  background: #fff;
  background-repeat: no-repeat;
  background-position: -10px -10px;
  content: '';
  -webkit-clip-path: path('M 9.849242404917499 24.091883092036785 A 5 5 0 0 1 13.384776310850237 22.627416997969522 L 20.627416997969522 22.627416997969522 A 2 2 0 0 0 22.627416997969522 20.627416997969522 L 22.627416997969522 13.384776310850237 A 5 5 0 0 1 24.091883092036785 9.849242404917499 L 23.091883092036785 9.849242404917499 L 9.849242404917499 23.091883092036785 Z');
          clip-path: path('M 9.849242404917499 24.091883092036785 A 5 5 0 0 1 13.384776310850237 22.627416997969522 L 20.627416997969522 22.627416997969522 A 2 2 0 0 0 22.627416997969522 20.627416997969522 L 22.627416997969522 13.384776310850237 A 5 5 0 0 1 24.091883092036785 9.849242404917499 L 23.091883092036785 9.849242404917499 L 9.849242404917499 23.091883092036785 Z');
}
.ant-popover-placement-top .ant-popover-arrow,
.ant-popover-placement-topLeft .ant-popover-arrow,
.ant-popover-placement-topRight .ant-popover-arrow {
  bottom: 0;
  transform: translateY(100%);
}
.ant-popover-placement-top .ant-popover-arrow-content,
.ant-popover-placement-topLeft .ant-popover-arrow-content,
.ant-popover-placement-topRight .ant-popover-arrow-content {
  box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07);
  transform: translateY(-11px) rotate(45deg);
}
.ant-popover-placement-top .ant-popover-arrow {
  left: 50%;
  transform: translateY(100%) translateX(-50%);
}
.ant-popover-placement-topLeft .ant-popover-arrow {
  left: 16px;
}
.ant-popover-placement-topRight .ant-popover-arrow {
  right: 16px;
}
.ant-popover-placement-right .ant-popover-arrow,
.ant-popover-placement-rightTop .ant-popover-arrow,
.ant-popover-placement-rightBottom .ant-popover-arrow {
  left: 0;
  transform: translateX(-100%);
}
.ant-popover-placement-right .ant-popover-arrow-content,
.ant-popover-placement-rightTop .ant-popover-arrow-content,
.ant-popover-placement-rightBottom .ant-popover-arrow-content {
  box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07);
  transform: translateX(11px) rotate(135deg);
}
.ant-popover-placement-right .ant-popover-arrow {
  top: 50%;
  transform: translateX(-100%) translateY(-50%);
}
.ant-popover-placement-rightTop .ant-popover-arrow {
  top: 12px;
}
.ant-popover-placement-rightBottom .ant-popover-arrow {
  bottom: 12px;
}
.ant-popover-placement-bottom .ant-popover-arrow,
.ant-popover-placement-bottomLeft .ant-popover-arrow,
.ant-popover-placement-bottomRight .ant-popover-arrow {
  top: 0;
  transform: translateY(-100%);
}
.ant-popover-placement-bottom .ant-popover-arrow-content,
.ant-popover-placement-bottomLeft .ant-popover-arrow-content,
.ant-popover-placement-bottomRight .ant-popover-arrow-content {
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.06);
  transform: translateY(11px) rotate(-135deg);
}
.ant-popover-placement-bottom .ant-popover-arrow {
  left: 50%;
  transform: translateY(-100%) translateX(-50%);
}
.ant-popover-placement-bottomLeft .ant-popover-arrow {
  left: 16px;
}
.ant-popover-placement-bottomRight .ant-popover-arrow {
  right: 16px;
}
.ant-popover-placement-left .ant-popover-arrow,
.ant-popover-placement-leftTop .ant-popover-arrow,
.ant-popover-placement-leftBottom .ant-popover-arrow {
  right: 0;
  transform: translateX(100%);
}
.ant-popover-placement-left .ant-popover-arrow-content,
.ant-popover-placement-leftTop .ant-popover-arrow-content,
.ant-popover-placement-leftBottom .ant-popover-arrow-content {
  box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07);
  transform: translateX(-11px) rotate(-45deg);
}
.ant-popover-placement-left .ant-popover-arrow {
  top: 50%;
  transform: translateX(100%) translateY(-50%);
}
.ant-popover-placement-leftTop .ant-popover-arrow {
  top: 12px;
}
.ant-popover-placement-leftBottom .ant-popover-arrow {
  bottom: 12px;
}
.ant-popover-pink .ant-popover-inner {
  background-color: #eb2f96;
}
.ant-popover-pink .ant-popover-arrow-content {
  background-color: #eb2f96;
}
.ant-popover-magenta .ant-popover-inner {
  background-color: #eb2f96;
}
.ant-popover-magenta .ant-popover-arrow-content {
  background-color: #eb2f96;
}
.ant-popover-red .ant-popover-inner {
  background-color: #f5222d;
}
.ant-popover-red .ant-popover-arrow-content {
  background-color: #f5222d;
}
.ant-popover-volcano .ant-popover-inner {
  background-color: #fa541c;
}
.ant-popover-volcano .ant-popover-arrow-content {
  background-color: #fa541c;
}
.ant-popover-orange .ant-popover-inner {
  background-color: #fa8c16;
}
.ant-popover-orange .ant-popover-arrow-content {
  background-color: #fa8c16;
}
.ant-popover-yellow .ant-popover-inner {
  background-color: #fadb14;
}
.ant-popover-yellow .ant-popover-arrow-content {
  background-color: #fadb14;
}
.ant-popover-gold .ant-popover-inner {
  background-color: #faad14;
}
.ant-popover-gold .ant-popover-arrow-content {
  background-color: #faad14;
}
.ant-popover-cyan .ant-popover-inner {
  background-color: #13c2c2;
}
.ant-popover-cyan .ant-popover-arrow-content {
  background-color: #13c2c2;
}
.ant-popover-lime .ant-popover-inner {
  background-color: #a0d911;
}
.ant-popover-lime .ant-popover-arrow-content {
  background-color: #a0d911;
}
.ant-popover-green .ant-popover-inner {
  background-color: #52c41a;
}
.ant-popover-green .ant-popover-arrow-content {
  background-color: #52c41a;
}
.ant-popover-blue .ant-popover-inner {
  background-color: #1890ff;
}
.ant-popover-blue .ant-popover-arrow-content {
  background-color: #1890ff;
}
.ant-popover-geekblue .ant-popover-inner {
  background-color: #2f54eb;
}
.ant-popover-geekblue .ant-popover-arrow-content {
  background-color: #2f54eb;
}
.ant-popover-purple .ant-popover-inner {
  background-color: #722ed1;
}
.ant-popover-purple .ant-popover-arrow-content {
  background-color: #722ed1;
}
.ant-popover-rtl {
  direction: rtl;
  text-align: right;
}
.ant-popover-rtl .ant-popover-message-title {
  padding-right: 22px;
  padding-left: 16px;
}
.ant-popover-rtl .ant-popover-buttons {
  text-align: left;
}
.ant-popover-rtl .ant-popover-buttons button {
  margin-right: 8px;
  margin-left: 0;
}

/* Variables declaration */
/* prettier-ignore */
.rdp-root {
  --rdp-accent-color: blue; /* The accent color used for selected days and UI elements. */
  --rdp-accent-background-color: #f0f0ff; /* The accent background color used for selected days and UI elements. */

  --rdp-day-height: 44px; /* The height of the day cells. */
  --rdp-day-width: 44px; /* The width of the day cells. */
  
  --rdp-day_button-border-radius: 100%; /* The border radius of the day cells. */
  --rdp-day_button-border: 2px solid transparent; /* The border of the day cells. */
  --rdp-day_button-height: 42px; /* The height of the day cells. */
  --rdp-day_button-width: 42px; /* The width of the day cells. */
  
  --rdp-selected-border: 2px solid var(--rdp-accent-color); /* The border of the selected days. */
  --rdp-disabled-opacity: 0.5; /* The opacity of the disabled days. */
  --rdp-outside-opacity: 0.75; /* The opacity of the days outside the current month. */
  --rdp-today-color: var(--rdp-accent-color); /* The color of the today's date. */
  
  --rdp-dropdown-gap: 0.5rem;/* The gap between the dropdowns used in the month captons. */
  
  --rdp-months-gap: 2rem; /* The gap between the months in the multi-month view. */
  
  --rdp-nav_button-disabled-opacity: 0.5; /* The opacity of the disabled navigation buttons. */
  --rdp-nav_button-height: 2.25rem; /* The height of the navigation buttons. */
  --rdp-nav_button-width: 2.25rem; /* The width of the navigation buttons. */
  --rdp-nav-height: 2.75rem; /* The height of the navigation bar. */
  
  --rdp-range_middle-background-color: var(--rdp-accent-background-color); /* The color of the background for days in the middle of a range. */
  --rdp-range_middle-color: inherit;/* The color of the range text. */
  
  --rdp-range_start-color: white; /* The color of the range text. */
  --rdp-range_start-background: linear-gradient(var(--rdp-gradient-direction), transparent 50%, var(--rdp-range_middle-background-color) 50%); /* Used for the background of the start of the selected range. */
  --rdp-range_start-date-background-color: var(--rdp-accent-color); /* The background color of the date when at the start of the selected range. */
  
  --rdp-range_end-background: linear-gradient(var(--rdp-gradient-direction), var(--rdp-range_middle-background-color) 50%, transparent 50%); /* Used for the background of the end of the selected range. */
  --rdp-range_end-color: white;/* The color of the range text. */
  --rdp-range_end-date-background-color: var(--rdp-accent-color); /* The background color of the date when at the end of the selected range. */
  
  --rdp-week_number-border-radius: 100%; /* The border radius of the week number. */
  --rdp-week_number-border: 2px solid transparent; /* The border of the week number. */
  
  --rdp-week_number-height: var(--rdp-day-height); /* The height of the week number cells. */
  --rdp-week_number-opacity: 0.75; /* The opacity of the week number. */
  --rdp-week_number-width: var(--rdp-day-width); /* The width of the week number cells. */
  --rdp-weeknumber-text-align: center; /* The text alignment of the weekday cells. */

  --rdp-weekday-opacity: 0.75; /* The opacity of the weekday. */
  --rdp-weekday-padding: 0.5rem 0rem; /* The padding of the weekday. */
  --rdp-weekday-text-align: center; /* The text alignment of the weekday cells. */

  --rdp-gradient-direction: 90deg;
}
.rdp-root[dir="rtl"] {
  --rdp-gradient-direction: -90deg;
}
.rdp-root[data-broadcast-calendar="true"] {
  --rdp-outside-opacity: unset;
}
/* Root of the component. */
.rdp-root {
  position: relative; /* Required to position the navigation toolbar. */
  box-sizing: border-box;
}
.rdp-root * {
  box-sizing: border-box;
}
.rdp-day {
  width: var(--rdp-day-width);
  height: var(--rdp-day-height);
  text-align: center;
}
.rdp-day_button {
  background: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  font: inherit;
  color: inherit;
  justify-content: center;
  align-items: center;
  display: flex;

  width: var(--rdp-day_button-width);
  height: var(--rdp-day_button-height);
  border: var(--rdp-day_button-border);
  border-radius: var(--rdp-day_button-border-radius);
}
.rdp-day_button:disabled {
  cursor: revert;
}
.rdp-caption_label {
  z-index: 1;

  position: relative;
  display: inline-flex;
  align-items: center;

  white-space: nowrap;
  border: 0;
}
.rdp-dropdown:focus-visible ~ .rdp-caption_label {
  outline: 5px auto Highlight;
  outline: 5px auto -webkit-focus-ring-color;
}
.rdp-button_next,
.rdp-button_previous {
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  font: inherit;
  color: inherit;
  -moz-appearance: none;
  -webkit-appearance: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  appearance: none;

  width: var(--rdp-nav_button-width);
  height: var(--rdp-nav_button-height);
}
.rdp-button_next:disabled,
.rdp-button_previous:disabled {
  cursor: revert;

  opacity: var(--rdp-nav_button-disabled-opacity);
}
.rdp-chevron {
  display: inline-block;
  fill: var(--rdp-accent-color);
}
.rdp-root[dir="rtl"] .rdp-nav .rdp-chevron {
  transform: rotate(180deg);
  transform-origin: 50%;
}
.rdp-dropdowns {
  position: relative;
  display: inline-flex;
  align-items: center;
  gap: var(--rdp-dropdown-gap);
}
.rdp-dropdown {
  z-index: 2;

  /* Reset */
  opacity: 0;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  position: absolute;
  inset-block-start: 0;
  inset-block-end: 0;
  inset-inline-start: 0;
  width: 100%;
  margin: 0;
  padding: 0;
  cursor: inherit;
  border: none;
  line-height: inherit;
}
.rdp-dropdown_root {
  position: relative;
  display: inline-flex;
  align-items: center;
}
.rdp-dropdown_root[data-disabled="true"] .rdp-chevron {
  opacity: var(--rdp-disabled-opacity);
}
.rdp-month_caption {
  display: flex;
  align-content: center;
  height: var(--rdp-nav-height);
  font-weight: bold;
  font-size: large;
}
.rdp-months {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  gap: var(--rdp-months-gap);
  max-width: -moz-fit-content;
  max-width: fit-content;
}
.rdp-month_grid {
  border-collapse: collapse;
}
.rdp-nav {
  position: absolute;
  inset-block-start: 0;
  inset-inline-end: 0;

  display: flex;
  align-items: center;

  height: var(--rdp-nav-height);
}
.rdp-weekday {
  opacity: var(--rdp-weekday-opacity);
  padding: var(--rdp-weekday-padding);
  font-weight: 500;
  font-size: smaller;
  text-align: var(--rdp-weekday-text-align);
  text-transform: var(--rdp-weekday-text-transform);
}
.rdp-week_number {
  opacity: var(--rdp-week_number-opacity);
  font-weight: 400;
  font-size: small;
  height: var(--rdp-week_number-height);
  width: var(--rdp-week_number-width);
  border: var(--rdp-week_number-border);
  border-radius: var(--rdp-week_number-border-radius);
  text-align: var(--rdp-weeknumber-text-align);
}
/* DAY MODIFIERS */
.rdp-today:not(.rdp-outside) {
  color: var(--rdp-today-color);
}
.rdp-selected {
  font-weight: bold;
  font-size: large;
}
.rdp-selected .rdp-day_button {
  border: var(--rdp-selected-border);
}
.rdp-outside {
  opacity: var(--rdp-outside-opacity);
}
.rdp-disabled {
  opacity: var(--rdp-disabled-opacity);
}
.rdp-hidden {
  visibility: hidden;
  color: var(--rdp-range_start-color);
}
.rdp-range_start {
  background: var(--rdp-range_start-background);
}
.rdp-range_start .rdp-day_button {
  background-color: var(--rdp-range_start-date-background-color);
  color: var(--rdp-range_start-color);
}
.rdp-range_middle {
  background-color: var(--rdp-range_middle-background-color);
}
.rdp-range_middle .rdp-day_button {
  border-color: transparent;
  border: unset;
  border-radius: unset;
  color: var(--rdp-range_middle-color);
}
.rdp-range_end {
  background: var(--rdp-range_end-background);
  color: var(--rdp-range_end-color);
}
.rdp-range_end .rdp-day_button {
  color: var(--rdp-range_start-color);
  background-color: var(--rdp-range_end-date-background-color);
}
.rdp-range_start.rdp-range_end {
  background: revert;
}
.rdp-focusable {
  cursor: pointer;
}

